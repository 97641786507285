<header class="rdc-apps-header">
    <h4>Sustainability cloud</h4>

    <button
        rdcAppsButton
        rdcAppsIconButton
        rdcAppsDialogItem
        display="compact"
        rdcAppsTooltip
        tooltipPosition="right"
        [tooltipText]="'Close'"
        [dialogCloseIconBtn]="true"
    >
        <rdc-apps-icon size="sm">close</rdc-apps-icon>
    </button>
</header>

<div class="body">
    <h5>Your dashboards</h5>

    @for(link of sustainabilityLinks; track link.title; let index = $index) {
        <rdc-apps-sustainability-item
            pendoElement="sidebar-sustainability-link"
            launchSource="Sidebar"
            [link]="link"
            [pendoIndex]="index">
        </rdc-apps-sustainability-item>
    }
</div>
