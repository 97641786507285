import { Route } from '@angular/router';
import {
    LegacyActivityGuard,
    AuthorizationGuard, ApexOnboardedGuard,
} from '@rdc-apps/rdc-apex/src/lib/shared/data-access/guards';
import { DataPointsResolver } from 'rdc-apex-store';
import {
    ApexLayoutComponent,
    TempUserAuthorizeComponent,
    TempUserLoginComponent,
    TempUserSigninOidcCallbackComponent
} from "shell-ui";


export const apexRoutes: Route[] = [
    {
        path: '',
        redirectTo: '/home',
        pathMatch: 'full',
    },
    {
        path: '',
        component: ApexLayoutComponent,
        canActivate: [ AuthorizationGuard ],
        resolve: { DataPointsResolver },
        data: { breadcrumbLabel: 'Home', icon: 'n-icon-home-fill' },
        children: [
            {
                path: 'home',
                canActivate: [ LegacyActivityGuard ],
                loadChildren: async() => (await import('@rdc-apps/rdc-apex/src/lib/home/feature/shell')).HomeShellModule,
            },
            {
                path: 'query-builder',
                canActivate: [ ApexOnboardedGuard ],
                loadChildren: async() => (await import('@rdc-apps/rdc-apex/src/lib/query-builder/feature/shell')).QueryBuilderShellModule,
            },
            {
                path: 'growth',
                canActivate: [ ApexOnboardedGuard ],
                loadChildren: async() => (await import('@rdc-apps/rdc-apex/src/lib/growth/feature/shell')).GrowthShellModule,
                data: { breadcrumbLabel: 'Growth cloud', icon: 'n-icon-growth-fill' },
            },
            {
                path: 'network',
                canActivate: [ ApexOnboardedGuard ],
                loadChildren: async() => (await import('@rdc-apps/rdc-apex/src/lib/network/feature/shell')).NetworkShellModule,
                data: { breadcrumbLabel: 'Network cloud', icon: 'n-icon-network-fill' },
            },
            {
                path: 'risk',
                canActivate: [ ApexOnboardedGuard ],
                loadChildren: async() => (await import('@rdc-apps/rdc-apex/src/lib/risk/feature/shell')).RiskShellModule,
                data: { breadcrumbLabel: 'Risk cloud', icon: 'n-icon-risk-fill' },
            },
            {
                path: 'performance',
                canActivate: [ ApexOnboardedGuard ],
                loadChildren: async() => (await import('@rdc-apps/rdc-apex/src/lib/performance/feature/shell')).PerformanceShellModule,
                data: { breadcrumbLabel: 'Performance cloud', icon: 'n-icon-performance-fill' },
            },
            {
                path: 'sustainability',
                canActivate: [ ApexOnboardedGuard ],
                loadChildren: async() => (await import('@rdc-apps/rdc-apex/src/lib/sustainability/feature/shell')).SustainabilityShellModule,
                data: { breadcrumbLabel: 'Sustainability cloud', icon: 'n-icon-sustainability-fill-alt', breadcrumbFill: '#43e08c' },
            },
        ],
    },
    {
        path: 'authorize',
        component: TempUserAuthorizeComponent,
    },
    {
        path: 'signin-oidc-callback',
        component: TempUserSigninOidcCallbackComponent,
    },
    {
        path: 'silent-renew',
        component: TempUserAuthorizeComponent,
    },
    {
        path: 'temp/login/user/:userId',
        component: TempUserLoginComponent,
    },
    {
        path: 'error',
        loadChildren: async() => (await import('@rdc-apps/rdc-apex/src/lib/error-page/feature/shell')).ErrorPageShellModule,
    },
    {
        path: '**',
        redirectTo: 'error',
    },
];
