import { Icon } from "@rdc-apps/rdc-shared/src/lib/data-access/models";

export interface SustainabilityLink {
    title: string,
    subtext: string,
    activityCode: string,
    icon: string,
    route: string[],
    iconSize?: Icon,
}

export const sustainabilityLinks: SustainabilityLink[] = [
    {
        title: 'Home dashboard',
        subtext: 'Track annual and monthly</br>emissions',
        icon: 'n-icon-home-alt',
        activityCode: 'rdc.q.apex.sustainability.home-dashboard.view',
        iconSize: 'xxxxs',
        route: [ '/', 'sustainability' ],
    },
    {
        title: 'Airline dashboard',
        subtext: 'Understand airline and aircraft emissions in detail',
        icon: 'n-icon-airlines',
        activityCode: 'rdc.q.apex.sustainability.airline-dashboard.view',
        iconSize: 'xxxxs',
        route: [ '/', 'sustainability', 'airline' ],
    },
    {
        title: 'Market dashboard',
        subtext: 'Identify the profile of emissions by destination',
        icon: 'n-icon-plane',
        iconSize: 'xxxxs',
        activityCode: 'rdc.q.apex.sustainability.market-dashboard.view',
        route: [ '/', 'sustainability', 'market' ],
    },
    {
        title: 'Benchmark dashboard',
        subtext: 'Compare performance against other airports',
        icon: 'n-icon-benchmark',
        iconSize: 'xxxxs',
        activityCode: 'rdc.q.apex.sustainability.benchmark-dashboard.view',
        route: [ '/', 'sustainability', 'benchmark' ],
    }
]
